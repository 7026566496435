import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Intro from '../components/sections/intro'

const IndexPage = ({ data }) => {
  console.log(data)
  
  return(
    <Layout >
      <SEO title="Home" />
        <Intro />
    </Layout>
  )
}

export default IndexPage
